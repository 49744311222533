import linkScrollerButtons from "./scroller-buttons";
import Glide from "@glidejs/glide";

window.addEventListener("load", function () {
  linkScrollerButtons("nav__button", "section", 3);

  const Slider = new Glide(".glide").mount();

  if (process.env.NODE_ENV !== "development") {
    console.error = () => {};
  }
});
