const linkScrollerButtons = (navButtonClass, sectionId, numberOfButtons) => {
  for (var i = 1; i <= numberOfButtons; i++) {
    const button = document.querySelector(`.${navButtonClass}--${i}`);
    const section = document.querySelector(`#${sectionId}--${i}`);
    button.addEventListener("click", () => {
      section.scrollIntoView({ behavior: "smooth" });
    });
  }
};

export default linkScrollerButtons;
